<template>
  <q-page padding class="q-pb-xl">
    <div class="q-col-gutter-md row">
      <div v-if="$q.screen.lt.md" class="col relative-position">
        <q-responsive :ratio="4 / 3">
          <div class="fit relative-position">
            <image-component v-if="data?.image" :image="data.image" />
            <q-card class="absolute-center bg-info" flat>
              <q-card-section v-if="data?.title" class="text-h5">{{
                data.title
              }}</q-card-section>
            </q-card>
          </div>
        </q-responsive>
      </div>
      <div class="col-xs-12 col-md-6">
        <div class="q-px-xl">
          <div ref="motionRef">
            <text-body v-if="data?.body" :body="data.body" />
          </div>
          <component :is="pageComponent($route.params.slug)" />
          <div
            v-if="
              $route.params.slug == 'about' || $route.params.slug == 'services'
            "
            class="text-center"
          >
            <dialog-button />
          </div>
        </div>
      </div>
    </div>
    <q-page-sticky
      v-if="$q.screen.gt.sm"
      position="top-right"
      :offset="[16, 16]"
    >
      <q-responsive :ratio="4 / 3" :style="`width: ${$q.screen.width / 2}px`">
        <div class="fit relative-position">
          <image-component v-if="data?.image" :image="data.image" />
          <q-card class="absolute-top-left bg-info q-ma-xl" flat>
            <q-card-section v-if="data?.title" class="text-h2">{{
              data.title
            }}</q-card-section>
          </q-card>
        </div>
      </q-responsive>
    </q-page-sticky>
    <q-page-scroller
      position="bottom-right"
      :scroll-offset="800"
      :offset="[18, 18]"
    >
      <q-btn fab icon="keyboard_arrow_up" color="accent" unelevated />
    </q-page-scroller>
  </q-page>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { keepPreviousData, useQuery } from "@tanstack/vue-query";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useMeta } from "quasar";
import { queryPage } from "src/api/sanity/queries/page";
import { i18n } from "src/boot/i18n";
import { queryClient } from "src/boot/vueQuery";
import { useDehydrateQueryClient } from "src/composables/useDehydrateQueryClient";
import { useFromLeft } from "src/composables/animation/useFromLeft";
import { useHydrateQueryClient } from "src/composables/useHydrateQueryClient";
import { useQueryErrorHandler } from "src/composables/useQueryErrorHandler";
import { useUpdateStoreFromQuery } from "src/composables/useUpdateStoreFromQuery";
import { usePageStore } from "src/stores/page";
import { usePreferencesStore } from "src/stores/preferences";
import ContactForm from "src/components/contact/ContactForm.vue";
import DialogButton from "src/components/dialog/DialogButton.vue";
import ImageComponent from "src/components/image/ImageComponent.vue";
import PricingForm from "src/components/pricing/PricingForm.vue";
import ServicesList from "src/components/services/ServicesList.vue";
import TextBody from "src/components/text/TextBody.vue";

const QKEY = "BasicPage";

defineOptions({
  name: QKEY,
  async preFetch({ store, currentRoute, ssrContext, router }) {
    const slug = currentRoute.params.slug;
    const pageStore = usePageStore(store);
    try {
      await queryClient.prefetchQuery({
        queryKey: [QKEY, slug],
        queryFn: () => queryPage({ slug }),
      });
      pageStore.page = queryClient.getQueryData([QKEY, slug]);
      useDehydrateQueryClient(ssrContext);
    } catch (e) {
      console.error("Failed to execute preFetch queryPage", e);
    }
  },
});

const { t } = i18n.global;
const router = useRouter();
const pageStore = usePageStore();
const { page } = storeToRefs(pageStore);
const preferencesStore = usePreferencesStore();
const { motion } = storeToRefs(preferencesStore);
const motionRef = ref(null);
const motionInstance = ref(null);

const { setupMotion } = useFromLeft(motionRef);

const slug = computed(() => router.currentRoute.value.params.slug || HOME);
const queryKey = computed(() => [QKEY, slug.value]);
const variables = computed(() => {
  return { slug: slug.value };
});

const pageComponent = (slug) => {
  switch (slug) {
    case "contact":
      return ContactForm;
    case "pricing":
      return PricingForm;
    case "services":
      return ServicesList;
    default:
      break;
  }
};

const { data, error, isError, isFetching, isLoading, isPending, refetch } =
  useQuery({
    queryKey: [queryKey.value],
    queryFn: () => queryPage(variables.value),
    initialData: page?.value,
    placeholderData: keepPreviousData,
  });

const metaDescription = t("meta.description");
const metaImage = t("meta.image");
const metaTitle = computed(() => `${t("meta.title")} - ${data?.value?.title}`);

useMeta(() => {
  return {
    meta: {
      description: {
        name: "description",
        content: metaDescription,
      },
      ogDescription: {
        property: "og:description",
        content: metaDescription,
      },
      ogTitle: {
        property: "og:title",
        content: metaTitle.value,
      },
      ogImage: {
        property: "og:image",
        content: metaImage,
      },
    },
    title: metaTitle.value,
  };
});

onMounted(async () => {
  if (motion.value) {
    await nextTick();
    if (motionRef.value) {
      motionInstance.value = setupMotion();
      motionInstance.value.apply("enter");
    }
  }
});

watch(
  queryKey,
  () => {
    refetch();
  },
  { deep: true }
);

useQueryErrorHandler(error, isError);
useUpdateStoreFromQuery(data, page);
useHydrateQueryClient();
</script>
