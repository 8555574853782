<template>
  <q-form @submit="sendEmail()">
    <q-card :class="`bg-${color}`" flat ref="motionRef">
      <q-card-section>
        <q-input
          v-model="email"
          filled
          :label="t('contact.email.label')"
          no-error-icon
          type="email"
          :rules="[
            (val) => (val && val.length > 0) || t('contact.email.validation'),
          ]"
        />
        <q-input
          v-model="tel"
          filled
          :label="t('contact.telephone.label')"
          lazy-rules
          :rules="[
            (val) =>
              !val ||
              isValidPhoneNumber(val) ||
              t('contact.telephone.validation'),
          ]"
          no-error-icon
          type="tel"
        />
        <q-input v-model="message" filled autogrow label="Your Message..." />
      </q-card-section>
      <q-card-actions align="right" class="q-pa-md">
        <div v-if="showBrand" class="flex-grow">
          <svg width="131px" height="48px">
            <use href="#logoLockup"></use>
          </svg>
        </div>
        <q-btn
          color="accent"
          :label="t('contact.submit.label')"
          no-caps
          :size="buttonSize"
          text-color="dark"
          type="submit"
          unelevated
        />
        <q-btn
          v-if="showCancel"
          flat
          label="Cancel"
          no-caps
          :size="buttonSize"
          @click="onCloseClick()"
        />
      </q-card-actions>
    </q-card>
  </q-form>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { axios } from "src/boot/axios";
import { i18n } from "src/boot/i18n";
import { useFromLeft } from "src/composables/animation/useFromLeft";
import { usePreferencesStore } from "src/stores/preferences";

defineOptions({ name: "ContactForm" });

const props = defineProps({
  buttonSize: { type: String, default: "lg" },
  color: { type: String, default: "secondary" },
  showBrand: { type: Boolean, default: false },
  showCancel: { type: Boolean, default: false },
});

const emits = defineEmits(["close", "submit"]);

const { t } = i18n.global;
const $q = useQuasar();
const preferencesStore = usePreferencesStore();
const { motion } = storeToRefs(preferencesStore);
const email = ref("");
const tel = ref("");
const message = ref("");
const motionRef = ref(null);
const motionInstance = ref(null);

const { setupMotion } = useFromLeft(motionRef);

const isValidPhoneNumber = (val) => {
  const phoneRegex = /^(\(\d{3}\)\s?|\d{3}[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
  return phoneRegex.test(val);
};

const onCloseClick = () => {
  emits("close");
};

const sendEmail = async () => {
  try {
    const response = await axios.post("/api/send-email", {
      to: "chris@quezada.work",
      from: email.value,
      subject: "Somome has contacted HipHouse with a question...",
      htmlBody: `<p>From: ${email.value}</p><p>Telephone: ${tel.value}</p><p>Message: ${message.value}</p>`,
    });
    $q.notify({ type: "info", message: "Email sent successfully!" });
  } catch (error) {
    $q.notify({
      type: "negative",
      message: "Failed to send email.",
      textColor: "white",
    });
    console.log(error);
  }
  emits("submit");
};

onMounted(() => {
  if (motion.value) {
    motionInstance.value = setupMotion();
    motionInstance.value.apply("enter");
  }
});
</script>
