import { ref } from "vue";
import { defineStore } from "pinia";

export const usePageStore = defineStore("page", () => {
  const page = ref({
    slug: null,
    title: null,
  });

  return { page };
});
