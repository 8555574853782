<template>
  <q-card class="bg-secondary q-mb-xl" flat ref="motionRef">
    <q-card-section>
      <q-list>
        <q-item
          v-for="tag in settings.tags"
          :class="{ column: $q.screen.lt.md }"
          class="q-mb-lg"
          :key="tag.title"
        >
          <q-item-section :class="{ 'q-pb-md': $q.screen.lt.md }" top>
            <div class="text-h5" style="line-height: 1">
              {{ tag.title }}
            </div>
          </q-item-section>
          <q-item-section>
            <div class="text-small" style="line-height: 1.125">
              {{ tag.caption }}
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
  </q-card>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useFromLeft } from "src/composables/animation/useFromLeft";
import { usePreferencesStore } from "src/stores/preferences";
import { useSettingsStore } from "src/stores/settings";

defineOptions({ name: "ServicesList" });

const preferencesStore = usePreferencesStore();
const { motion } = storeToRefs(preferencesStore);
const settingsStore = useSettingsStore();
const { settings } = storeToRefs(settingsStore);
const motionRef = ref(null);
const motionInstance = ref(null);

const { setupMotion } = useFromLeft(motionRef);

onMounted(() => {
  if (motion.value) {
    motionInstance.value = setupMotion();
    motionInstance.value.apply("enter");
  }
});
</script>
