<template>
  <q-form @submit="sendEmail()">
    <q-card :class="`bg-${color}`" flat ref="motionRef">
      <q-card-section>
        <pricing-slider />
      </q-card-section>
      <q-card-section class="text-center text-h6">
        {{ t("cta.label") }}
      </q-card-section>
      <q-card-section>
        <q-input
          v-model="email"
          filled
          :label="t('contact.email.label')"
          no-error-icon
          type="email"
          :rules="[
            (val) => (val && val.length > 0) || t('contact.email.validation'),
          ]"
        />
        <q-input
          v-model="tel"
          filled
          :label="t('contact.telephone.label')"
          lazy-rules
          :rules="[
            (val) =>
              !val ||
              isValidPhoneNumber(val) ||
              t('contact.telephone.validation'),
          ]"
          no-error-icon
          type="tel"
        />
        <q-input v-model="message" filled autogrow label="Your Message..." />
      </q-card-section>
      <q-card-actions align="right" class="q-pa-md">
        <div v-if="showBrand" class="flex-grow">
          <svg width="131px" height="48px">
            <use href="#logoLockup"></use>
          </svg>
        </div>
        <q-btn
          color="accent"
          :label="t('contact.submit.label')"
          no-caps
          :size="buttonSize"
          text-color="dark"
          type="submit"
          unelevated
        />
        <q-btn
          v-if="showCancel"
          flat
          label="Cancel"
          no-caps
          :size="buttonSize"
          @click="onCloseClick()"
        />
      </q-card-actions>
    </q-card>
  </q-form>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { axios } from "src/boot/axios";
import { i18n } from "src/boot/i18n";
import { useFromLeft } from "src/composables/animation/useFromLeft";
import { isColorDark } from "src/composables/useRandomPalette";
import { usePreferencesStore } from "src/stores/preferences";
import { useSettingsStore } from "src/stores/settings";
import PricingSlider from "./PricingSlider.vue";

defineOptions({ name: "PricingForm" });

const props = defineProps({
  buttonSize: { type: String, default: "lg" },
  color: { type: String, default: "secondary" },
  showBrand: { type: Boolean, default: false },
  showCancel: { type: Boolean, default: false },
});

const emits = defineEmits(["close", "submit"]);

const { t } = i18n.global;
const preferencesStore = usePreferencesStore();
const { motion } = storeToRefs(preferencesStore);
const settingsStore = useSettingsStore();
const { computedPrice, selectedPalette, squareFootage } =
  storeToRefs(settingsStore);
const $q = useQuasar();
const buttonColor = ref("dark");
const dark = ref(false);
const email = ref("");
const tel = ref("");
const textColor = ref("dark");
const message = ref("");
const motionRef = ref(null);
const motionInstance = ref(null);

const { setupMotion } = useFromLeft(motionRef);

const handleButtonColor = () => {
  buttonColor.value = isColorDark(
    selectedPalette.value.accent?.hex || "#000000"
  )
    ? "white"
    : "dark";
};

const handleDark = () => {
  dark.value = isColorDark(
    selectedPalette.value[props.color]?.hex || "#000000"
  );
};

const handleTextColor = () => {
  textColor.value = isColorDark(
    selectedPalette.value[props.color]?.hex || "#000000"
  )
    ? "white"
    : "dark";
};

const isValidPhoneNumber = (val) => {
  const phoneRegex = /^(\(\d{3}\)\s?|\d{3}[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
  return phoneRegex.test(val);
};

const onCloseClick = () => {
  emits("close");
};

const sendEmail = async () => {
  try {
    const response = await axios.post("/api/send-email", {
      to: "chris@quezada.work",
      from: email.value,
      subject: "A homeowner wants a HipHouse!",
      htmlBody: `<p>From: ${email.value}</p><p>Telephone: ${tel.value}</p><p>Message: ${message.value}</p><p>${squareFootage.value} sq.ft. priced at $${computedPrice.value}</p>`,
    });
    $q.notify({ type: "info", message: "Email sent successfully!" });
  } catch (error) {
    $q.notify({
      type: "negative",
      message: "Failed to send email.",
      textColor: "white",
    });
    console.log(error);
  }
  emits("submit");
};

onMounted(() => {
  handleButtonColor();
  handleDark();
  handleTextColor();
  if (motion.value) {
    motionInstance.value = setupMotion();
    motionInstance.value.apply("enter");
  }
});

watch(
  selectedPalette,
  () => {
    handleButtonColor();
    handleDark();
    handleTextColor();
  },
  { deep: true }
);
</script>
