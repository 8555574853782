import { sanityClient } from "src/boot/sanity";

export const pageQuery = `*[_type == "page" && slug.current == $slug]{
  _createdAt,
  _id,
  _rev,
  _type,
  _updatedAt,
  body,
  image {
    _type,
    asset->
  },
  slug,
  title,
}[0]`;

export const queryPage = async (slug) => {
  try {
    const result = await sanityClient.fetch(pageQuery, slug);
    return result;
  } catch (error) {
    console.error("Failed to execute queryPage ", error);
  }
};
